import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteURL = this.props.data.site.siteMetadata.url
    if (post.frontmatter.tags && post.frontmatter.tags.length > 0) {
      var genre = post.frontmatter.tags
        .filter(tag => tag.startsWith("genre-"))[0]
        .substring(6)
    }
    console.log(
      "post.frontmatter.thumbnail?.childImageSharp:",
      post.frontmatter.thumbnail?.childImageSharp
    )
    console.log("genre", genre)
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={`"${post.frontmatter.title}", written by AI`}
          description={post.frontmatter.description || post.excerpt}
          image={
            `${siteURL}${post.frontmatter.thumbnail?.childImageSharp.fluid.src}` ||
            undefined
          }
        />
        <article
          className={`post-content ${post.frontmatter.thumbnail || `no-image`}`}
        >
          <header className="post-content-header">
            <h1 className="post-content-title">{post.frontmatter.title}</h1>
          </header>
          {post.frontmatter.description && (
            <p className="post-content-excerpt">
              {post.frontmatter.description}
            </p>
          )}
          {genre && (
            <div className="tag-container blog-tag-container">
              <a
                href={`/tags/genre-${genre}`}
                className="blog-post-genre"
                style={{ textDecoration: "none" }}
              >
                <div className="tag-item">{genre}</div>
              </a>
            </div>
          )}

          {post.frontmatter.thumbnail && (
            <figure className="post-content-image kg-card kg-image-card kg-width-wide">
              <Img
                className="kg-image"
                fluid={post.frontmatter.thumbnail?.childImageSharp?.fluid}
                alt={post.frontmatter.title}
              />
              {post.frontmatter.caption && (
                <figcaption
                  dangerouslySetInnerHTML={{
                    __html: post.frontmatter.caption,
                  }}
                ></figcaption>
              )}
            </figure>
          )}

          <div
            className="post-content-body"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          <hr />
          <footer className="post-content-footer">
            {/* There are two options for how we display the byline/author-info.
        If the post has more than one author, we load a specific template
        from includes/byline-multiple.hbs, otherwise, we just use the
        default byline. */}
            {post.frontmatter.date && <p>Posted on: {post.frontmatter.date}</p>}
            {/* {post.frontmatter.generateddate && (
              <p>Generated on: {post.frontmatter.generateddate}</p>
            )} */}
            {post.frontmatter.tags && (
              <p class="tag-links">
                Found in:
                {post.frontmatter.tags.map(tag => (
                  <Link to={`/tags/${tag}`} key={tag}>
                    {tag}
                  </Link>
                ))}
              </p>
            )}
          </footer>
        </article>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $crop: ImageCropFocus) {
    site {
      siteMetadata {
        title
        author
        url
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        # generateddate(formatString: "MMMM DD, YYYY")
        description
        tags
        caption
        thumbnail {
          childImageSharp {
            fluid(
              maxWidth: 800
              maxHeight: 475
              cropFocus: $crop
              srcSetBreakpoints: [800]
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
